<template>
    <div class="materiaAllocate" v-loading="loading">
        <div v-if="!iView" class="complete-wrapper" @click="completeChange">
            <i class="el-icon-s-claim"></i>
            <p>完成分配</p>
        </div>
        <div class="header-container">
            <ul class="count-wrapper">
                <li class="count-item item_1">
                    <i class="el-icon-date"></i><span>要求进场时间</span>
                    <p>{{ plandetailsData.time_required }}</p>
                </li>
                <li class="count-item item_2">
                    <i class="el-icon-date"></i><span>计划日期</span>
                    <p>{{ plandetailsData.supply_date && plandetailsData.supply_date.substr(0,10) }}</p>
                </li>
                <li class="count-item item_3">
                    <i class="el-icon-location-outline"></i><span>场站信息</span>
                    <p>{{ plandetailsData.station_name }}</p>
                </li>
                <li class="count-item item_4">
                    <i class="el-icon-timer"></i><span>
                        申请时间</span>
                    <p>{{ plandetailsData.operation_date }}</p>
                </li>
                <li class="count-item item_5">
                    <i class="el-icon-s-custom"></i><span>申报人</span>
                    <p>{{ plandetailsData.operation_name }}</p>
                </li>
                <li class="count-item item_6">
                    <i class="el-icon-collection-tag"></i><span>状态</span>
                    <p>{{ plandetailsData.state_name }}</p>
                </li>
            </ul>
        </div>
        <div class="main-container">
            <div class="box-wrapper">
                <el-container>
                    <el-aside>
                        <div class="triangular"></div>
                        <div class="aside-wrapper">
                            <p>物料采购计划</p>
                            <div class="table-wrapper">
                                <table border="0" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>选择</th>
                                            <!-- <th>物料编码</th> -->
                                            <th>原材料分类</th>
                                            <th>原材料子类</th>
                                            <th>原材料规格</th>
                                            <th>计划数量</th>
                                            <th>计量单位</th>
                                            <th>已安排量</th>
                                            <th>是否限量</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(item,index) in materiaPuPlanData"
                                            :key="index"
                                            @click="handleChange(item)"
                                            :class="{ active: checked === item.id }"
                                        >
                                            <td>
                                                <input
                                                    type="radio"
                                                    name="name"
                                                    :value="item.id"
                                                    :id="index"
                                                    v-model="checked"
                                                ><label :for="index"><i class="el-icon-check"></i></label>
                                            </td>
                                            <!-- <td>{{ item.goods_no }}</td> -->
                                            <td>{{ item.type_name }}</td>
                                            <td>{{ item.goods_name }}</td>
                                            <td>{{ item.spec_name }}</td>
                                            <td>{{ item.plan_count }}</td>
                                            <td>{{ item.measure_unit }}</td>
                                            <td>{{ item.plan_supply_count }}</td>
                                            <td>
                                                <el-switch
                                                    v-model="item.is_restrict_supply"
                                                    :active-value="1"
                                                    :inactive-value="0"
                                                    @click.stop.native
                                                    @change="handleChangeRestrict(item)"
                                                    :disabled="plandetailsData.state == 2"
                                                ></el-switch>
                                            </td>
                                        </tr>
                                        <tr v-if="materiaPuPlanData<=0">
                                            <td colspan="8" style="color:#909399;border-bottom:1px solid rgba(238,236,241,1);">
                                                暂无数据
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </el-aside>
                    <el-container>
                        <el-header style="height: 3.1rem!important;">
                            <div class="header-wrapper">
                                <div class="title-wrapper">
                                    <p>可分配供应商</p>
                                    <el-input v-model="searchValue" placeholder="请输入供应商名称">
                                        <el-button slot="append" icon="el-icon-search" @click="supplierSearchChange"></el-button>
                                    </el-input>
                                </div>
                                <div class="table-wrapper">
                                    <el-table
                                        :data="CanBeAssignedSupplier"
                                        stripe
                                        fit
                                        style="width: 100%"
                                        height="100%"
                                        :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#5588F1', padding: '0'}"
                                    >
                                        <el-table-column
                                            prop="supplier_name"
                                            label="供应商/加工商"
                                            show-overflow-tooltip
                                        >
                                            <template slot-scope="scope">
                                                <div :class="{'custom-cell':true,'jiagong':scope.row.is_process_contract}">
                                                    {{ scope.row.supplier_name }}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="source_name"
                                            label="货源地"
                                            show-overflow-tooltip
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="daily_insurance_supply"
                                            label="日供应量"
                                            width="80"
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="assigned_supply"
                                            label="已分配量"
                                            width="80"
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="surplus_supply"
                                            label="剩余供应能力"
                                            width="100"
                                        >
                                        </el-table-column>

                                        <el-table-column
                                            label="操作"
                                            v-if="!iView"
                                            width="80"
                                        >
                                            <template slot-scope="scope">
                                                <!-- <el-popover
                                                    placement="left"
                                                    width="390"
                                                    trigger="manual"
                                                    v-model="scope.row.delPopover"
                                                    popper-class="allocate-popover"
                                                >
                                                    <div class="allcocate-con">
                                                        <div class="types">
                                                            <span>负责运输：</span>
                                                            <el-radio-group v-model="supplyType" @change="changeSupplyType">
                                                                <el-radio
                                                                    label="供应商"
                                                                ></el-radio>
                                                                <el-radio
                                                                    label="运输单位"
                                                                ></el-radio>
                                                            </el-radio-group>
                                                        </div>
                                                        <div class="main">
                                                            <div class="type1" v-if="supplyType==='供应商'">
                                                                <div class="text-center text">
                                                                    {{ saveData.transports[0].transport_name }}
                                                                </div>
                                                                <div class="text-center">
                                                                    <el-input
                                                                        placeholder="请输入分配量"
                                                                        type="number"
                                                                        v-model="saveData.transports[0].supply_count"
                                                                        @mousewheel.native.prevent
                                                                    ></el-input>
                                                                </div>
                                                            </div>
                                                            <div class="type2" v-else>
                                                                <div class="rows">
                                                                    <div
                                                                        class="row"
                                                                        v-for="(item, index) in saveData.transports"
                                                                        :key="index"
                                                                    >
                                                                        <el-input
                                                                            class="input1"
                                                                            placeholder=""
                                                                            @focus="choseSupply(index)"
                                                                            v-model="item.transport_name"
                                                                        >
                                                                            <i slot="suffix" class="iconfont icon009"></i>
                                                                        </el-input>
                                                                        <el-input
                                                                            class="input2"
                                                                            type="number"
                                                                            placeholder="分配量"
                                                                            @mousewheel.native.prevent
                                                                            v-model="item.supply_count"
                                                                        ></el-input>
                                                                        <span
                                                                            class="iconfont iconjianqu-fangxing-mianxing"
                                                                            @click="delSupplyRow(index)"
                                                                        ></span>
                                                                    </div>
                                                                </div>
                                                                <div class="new-row" @click="addSupplyRow">
                                                                    <span class="iconfont icontianjia1"></span>
                                                                    <span>点击此处新增</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="btn-con">
                                                        <el-button type="primary" size="mini" @click="batchSave()">
                                                            确定
                                                        </el-button>
                                                        <el-button
                                                            type="primary"
                                                            size="mini"
                                                            @click="scope.row.delPopover = false"
                                                            plain
                                                        >
                                                            取消
                                                        </el-button>
                                                    </div>
                                                </el-popover> -->
                                                <el-button slot="reference" @click="allocatePop(scope.row, scope.$index)">
                                                    分配
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </el-header>
                        <el-main>
                            <div class="main-wrapper">
                                <p>已分配供应商</p>
                                <div class="table-wrapper">
                                    <table border="0" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th>供应商/加工商</th>
                                                <th>供应单号</th>
                                                <!-- <th>物料类型</th> -->
                                                <!-- <th>物料名称</th> -->
                                                <!-- <th>规格型号</th> -->
                                                <th>货源地</th>
                                                <th>供应量</th>
                                                <th>运输单位</th>
                                                <template v-if="checkedData.is_restrict_supply">
                                                    <th>是否限制</th>
                                                    <th>限制进场重量</th>
                                                </template>
                                                <th v-if="!iView">
                                                    操作
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item,index) in allocatedSupplier" :key="index">
                                                <td>
                                                    <div :class="{'custom-cell':true,'jiagong':item.is_process_contract}">
                                                        {{ item.supplier_name }}
                                                    </div>
                                                </td>
                                                <td>{{ item.no }}</td>
                                                <!-- <td>{{ item.type_name }}</td> -->
                                                <!-- <td>{{ item.goods_name }}</td> -->
                                                <!-- <td>{{ item.spec_name }}</td>
                                                    style="color:#1577d2;cursor: pointer;text-decoration:underline"
                                                @click="modifySupplier(item)"-->
                                                <td>{{ item.source_name }}</td>
                                                <td>{{ item.supply_count }}</td>

                                                <td>
                                                    <span>
                                                        {{ item.transport_name || '选择' }}
                                                    </span>
                                                </td>
                                                <template v-if="checkedData.is_restrict_supply">
                                                    <td>{{ item.is_restrict_supply?'是':'否' }}</td>
                                                    <td>{{ item.restrict_supply_count }}</td>
                                                </template>
                                                <td v-if="!iView" @click="deleteChange(item)">
                                                    <i class="el-icon-delete"></i>
                                                </td>
                                            </tr>
                                            <tr v-if="allocatedSupplier<=0">
                                                <td colspan="8" style="color:#909399;border-bottom:1px solid rgba(238,236,241,1);">
                                                    暂无数据
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </el-main>
                    </el-container>
                </el-container>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/store';
export default {
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            // 控制物料采购计划列表选择的列
            checked: 1,
            // 控制物料采购计划列表选择数据
            checkedData: {},
            // 物料采购计划列表数据data
            materiaPuPlanData: [],
            // 可分配供应商列表数据data
            CanBeAssignedSupplier: [],
            // 复制可分配供应商列表数据data
            copyCanBeAssignedSupplier: [],
            // 已分配供应商列表数据data
            allocatedSupplier: [],
            // 计划申报信息存储对象
            plandetailsData: {},
            // IsView=true时是查看场景，否则可分配
            iView: null,
            // 根据供应商搜索可分配供应商列表
            searchValue: '',
            // 选中供应商
            checkedSupplier: {},
            supplierIndex: 0,
            // 供应类型
            supplyType: '供应商',
            // 供应商列表
            saveData: {
                catalog_supply_id: '',
                ciid: '',
                transports: [
                    {
                        supply_count: null,
                        transport_id: '',
                        transport_name: '',
                        transport_type: 0,
                    },
                ],
            },
            // 激活供应商index
            transportsIndex: 0,
        };
    },
    created() {
        this.iView = this.extr.isView;
    },
    mounted() {
        // 获取计划申报信息
        this.getProcurementdDailyPlan();
        // 获取申报物料信息
        this.getProcurementDailyPlanGoods();
    },
    methods: {
        // 根据供应商名称查询可分配列表
        supplierSearchChange() {
            let copyList = [];
            if (this.searchValue) {
                const list = this.copyCanBeAssignedSupplier.filter(item => {
                    return item.supplier_name.indexOf(this.searchValue) > -1;
                });
                copyList = list;
            } else {
                copyList = this.copyCanBeAssignedSupplier;
            }
            this.CanBeAssignedSupplier = copyList;
        },
        // 点击运输单位名称弹出配置的表格
        modifySupplier(item) {
            this.$toast(
                {
                    title: true,
                    text: '选择合同单位',
                    type: 'eject',
                    t_url: 'tableList/index',
                    extr: {
                        // eslint-disable-next-line max-len
                        code: { TableCode: 'cg_mrjhpf_xzysdw', QueryString: 'daily_plan_supply_id=' + item.id },
                        closeCallback: this.additionalCallback,
                    },
                }
            );
        },
        additionalCallback() {
            // 刷新已分配列表
            this.getProcurementDailyPlanSupplierSupply();
        },
        // 获取计划申报信息
        getProcurementdDailyPlan() {
            this.$axios
                .get('/interfaceApi/procurement/daily/plan/get/' + this.extr.id + '')
                .then(res => {
                    this.plandetailsData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取申报物料信息
        getProcurementDailyPlanGoods(checked) {
            this.$axios
                .get('/interfaceApi/procurement/daily/plan/allgoods/' + this.extr.id + '')
                .then(res => {
                    this.materiaPuPlanData = res;
                    // 默认选中第一条数据
                    this.checked = checked ? checked : this.materiaPuPlanData[0].id;
                    if (checked) {
                        this.materiaPuPlanData.forEach(item => {
                            if (item.id === checked) {
                                this.checkedData = item;
                            }
                        });
                    } else {
                        this.checkedData = this.materiaPuPlanData[0];
                    }
                    // 获取可分配供应商信息
                    this.getProcurementDailyPlanSupplierNosupply();
                    // 获取已分配供应商信息
                    this.getProcurementDailyPlanSupplierSupply();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取可分配供应商信息
        getProcurementDailyPlanSupplierNosupply() {
            this.$axios
                .get('/interfaceApi/procurement/daily/plan/supplier/get/' + this.checked + '')
                .then(res => {
                    this.CanBeAssignedSupplier = res;
                    this.copyCanBeAssignedSupplier = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取已分配供应商信息
        getProcurementDailyPlanSupplierSupply() {
            this.$axios
                .get('/interfaceApi/procurement/daily/plan/supply/get/' + this.checked + '')
                .then(res => {
                    this.allocatedSupplier = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择不同的物料信息查询不同的可分配、已分配供应商信息
        handleChange(item) {
            this.checked = item.id;
            this.checkedData = item;
            // 获取可分配供应商信息
            this.getProcurementDailyPlanSupplierNosupply();
            // 获取已分配供应商信息
            this.getProcurementDailyPlanSupplierSupply();
        },
        // 保存供应商信息分配
        pSubmit(row) {
            row.delPopover = false;
            if (row.is_interim === 1) {
                const count = row.assigned_supply + row.count;
                if (count > row.max_limit) {
                    this.$confirm(`该供应商分配量已超出最高限量${row.max_limit}，是否继续？`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.supplyFun(row);
                    });
                } else {
                    this.supplyFun(row);
                }
            } else {
                this.supplyFun(row);
            }
        },
        supplyFun(row) {
            this.$axios
                .post('/interfaceApi/procurement/daily/plan/supply/save/' + this.checked + '', {
                    catalog_supply_id: row.id,
                    supply_count: row.count,
                    ciid: row.ciid,
                })
                .then(() => {
                    // 获取申报物料信息
                    this.getProcurementDailyPlanGoods(this.checked);
                    // 获取可分配供应商信息
                    this.getProcurementDailyPlanSupplierNosupply();
                    // 获取已分配供应商信息
                    this.getProcurementDailyPlanSupplierSupply();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 删除已分配供应商
        deleteChange(row) {
            this.loading = true;
            this.$axios
                .delete('/interfaceApi/procurement/daily/plan/supply/delete/' + row.id + '')
                .then(() => {
                    // 获取申报物料信息
                    this.getProcurementDailyPlanGoods(this.checked);
                    // 获取可分配供应商信息
                    this.getProcurementDailyPlanSupplierNosupply();
                    // 获取已分配供应商信息
                    this.getProcurementDailyPlanSupplierSupply();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 完成分配处理函数
        completeChange() {
            this.$confirm('确定完成分配?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .get('/interfaceApi/procurement/daily/plan/complete/' + this.extr.id + '')
                    .then(() => {
                        this.$message({ type: 'success', message: '操作成功!' });
                        this.$parent.hide();
                        store.state.currentOpenList.forEach(item => {
                            item.tableObj.updateTable();
                        });
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            });

        },
        // 新增运输单位
        addSupplyRow() {
            const json = {
                supply_count: null,
                transport_id: '',
                transport_name: '',
                transport_type: 1,
            };
            this.saveData.transports.push(json);
        },
        // 删除运输单位
        delSupplyRow(index) {
            this.saveData.transports.splice(index, 1);
        },
        // 选择运输
        choseSupply(index) {
            this.transportsIndex = index;
            let transport_ids = '';
            this.saveData.transports.map(item => {
                if (item.transport_id) {
                    transport_ids += item.transport_id + ',';
                }
            });
            if (transport_ids) {
                transport_ids = transport_ids.substr(0, transport_ids.length - 1);
            }
            this.$toast({
                title: true,
                text: '选择运输单位',
                type: 'eject',
                width: '12rem',
                height: '80%',
                t_url: 'tableList/index',
                viewPosition: 'view',
                extr: {
                    code: { TableCode: 'cg_mrjhpf_xzysdw', QueryString: 'transport_ids=' + transport_ids },
                    closeBtn: {},
                    sureCallBack: this.selectedSupply,
                    tabledataParams: { transport_ids: transport_ids },
                },
            });
        },
        // 运输单位选择完成
        selectedSupply(data) {
            if (data.length === 1) {
                const json = {
                    supply_count: null,
                    transport_id: data[0].id,
                    transport_name: data[0].name,
                    transport_type: 1,
                };
                this.saveData.transports.splice(this.transportsIndex, 1, json);
            } else {
                this.saveData.transports.splice(this.transportsIndex, 1);
                data.map(item => {
                    const json = {
                        supply_count: null,
                        transport_id: item.id,
                        transport_name: item.name,
                        transport_type: 1,
                    };
                    this.saveData.transports.push(json);
                });
            }

        },
        // 保存分配供应信息
        async batchSave() {
            const arr = this.saveData.transports.filter(item => item.supply_count === null);
            if (arr.length > 0) {
                this.$message.warning('请输入分配量！');
                return;
            }
            const res = await this.batchSaveApi();
            if (res) {
                this.$message.success('分配成功！');
                this.CanBeAssignedSupplier[this.supplierIndex].delPopover = false;
                this.getProcurementDailyPlanSupplierSupply();
                this.getProcurementDailyPlanSupplierNosupply();
            }
        },
        // 更换方式
        changeSupplyType(value) {
            if (value === '供应商') {
                this.saveData.transports = [
                    {
                        supply_count: null,
                        transport_id: this.checkedSupplier.supplier_id,
                        transport_name: this.checkedSupplier.supplier_name,
                        transport_type: 0,
                    },
                ];
            } else {
                this.saveData.transports = [
                    {
                        supply_count: null,
                        transport_id: '',
                        transport_name: '',
                        transport_type: 1,
                    },
                ];
            }
        },
        // 分配拉取数据
        allocatePop(supplier) {
            this.$toast({
                title: true,
                text: '分配',
                type: 'eject',
                width: '8rem',
                height: '4.5rem',
                t_url: 'purchaseManage/materialManage/allocatePop',
                viewPosition: 'view',
                extr: {
                    closeBtn: {},
                    checkedMaterial: this.checked,
                    // 原材料任务数据
                    rawMaterialOrder: this.checkedData,
                    // 场站id
                    stationId: this.plandetailsData.station_id,
                    checkedSupplier: supplier,
                    getProcurementDailyPlanSupplierSupply: this.getProcurementDailyPlanSupplierSupply,
                    getProcurementDailyPlanSupplierNosupply: this.getProcurementDailyPlanSupplierNosupply,
                },
            });
            // this.supplierIndex = index;
            // this.CanBeAssignedSupplier.map(item => {
            //     item.delPopover = false;
            // });
            // supplier.delPopover = !supplier.delPopover;
            // this.saveData = {
            //     catalog_supply_id: '',
            //     ciid: '',
            //     transports: [
            //         {
            //             supply_count: null,
            //             transport_id: '',
            //             transport_name: '',
            //             transport_type: 0,
            //         },
            //     ],
            // };
            // this.checkedSupplier = supplier;
            // const res = await this.getSupply();
            // if (res) {
            //     if (res.length > 0) {
            //         if (res[0].transport_type === 0) {
            //             this.supplyType = '供应商';
            //         } else {
            //             this.supplyType = '运输单位';
            //         }
            //         this.saveData.catalog_supply_id = this.checkedSupplier.id;
            //         this.saveData.ciid = this.checkedSupplier.ciid;
            //         this.saveData.transports = res;
            //     } else {
            //         this.supplyType = '供应商';
            //         this.saveData.transports[0].transport_type = 0;
            //         this.saveData.transports[0].transport_id = this.checkedSupplier.supplier_id;
            //         this.saveData.transports[0].transport_name = this.checkedSupplier.supplier_name;
            //         this.saveData.catalog_supply_id = this.checkedSupplier.id;
            //         this.saveData.ciid = this.checkedSupplier.ciid;
            //     }
            // }
        },
        // 拉取分配数据
        getSupply() {
            const path = '/interfaceApi/procurement/daily/plan/supply/get/' + this.checked + '?supplier_id=' + this.checkedSupplier.supplier_id;
            return this.$axios.$get(path, { defEx: true });
        },
        // 保存分配供应信息
        batchSaveApi() {
            const path = '/interfaceApi/procurement/daily/plan/supply/batchsave/' + this.checked;
            return this.$axios.$post(path, this.saveData, { defEx: true });
        },

        // 切换阻止限制状态
        handleChangeRestrict(item) {
            const path = `/interfaceApi/procurement/daily/plan/goods/modify_isrestrictsupply/${item.id}/${item.is_restrict_supply}`;
            this.$axios.$put(path ).then(data => {
                if (data) {
                    this.getProcurementDailyPlanSupplierSupply();
                }
            });
        },
    },
};
</script>

<style lang="stylus">
    .allocate-popover
        position absolute!important
        .el-radio
            .el-radio__label
                font-size .14rem
        .btn-con
            text-align center
            margin: 0
        .main
            height 1.56rem
            margin .1rem 0
            .type1
                .el-input__inner
                    width 2rem
                .text
                    margin .3rem 0 .1rem 0
                input::-webkit-outer-spin-button
                          -webkit-appearance none !important
                          margin 0
                input::-webkit-inner-spin-button
                          -webkit-appearance none !important
                          margin 0
            .type2
                .rows
                    height 1.2rem
                    overflow-y auto
                    .row
                        margin-bottom .1rem
                        .input1
                            width calc(100% - 1.08rem)
                            line-height .32rem
                        .input2
                            width .7rem
                            margin 0 .1rem
                        .el-input__inner
                            height .32rem
                            line-height .32rem
                            padding 0 .08rem
                            &::-webkit-input-placeholder
                                font-size .14rem
                            &::-moz-placeholder
                                font-size .14rem
                            &::-ms-input-placeholder
                                font-size .14rem
                        input::-webkit-outer-spin-button
                                  -webkit-appearance none !important
                                  margin 0
                        input::-webkit-inner-spin-button
                                  -webkit-appearance none !important
                                  margin 0
                        .iconfont
                            font-size .18rem
                            color #D5000F
                            cursor pointer
                        .icon009
                            color #979EA7
                .new-row
                    height .32rem
                    border 1px dashed #D7D7D7
                    background #FCFCFC
                    text-align center
                    line-height .32rem
                    cursor pointer
                    .iconfont
                        margin-right .05rem
        .text-center
            text-align center
    .materiaAllocate
        width 100%
        height 100%
        background rgba(240,240,240,1)
        position relative
        display flex
        flex-direction column
        overhlow hidden
        .complete-wrapper
            position absolute
            width 0.8rem
            height 0.6rem
            top -0.6rem
            right 0.6rem
            font-size 0.16rem
            text-align center
            color #ffffff
            cursor pointer
            i
                font-size 0.18rem
                padding-top 0.14rem
            p
                color #ffffff
        .header-container
            width 100%
            // height 1.2rem
            background #ffffff
            // padding-bottom 0.1rem
            .count-wrapper
                display flex
                padding 0.1rem
                .count-item
                    flex 1
                    height 0.8rem
                    padding 0.1rem
                    border 0.01rem solid #D6D6D8
                    font-size 0.14rem
                    margin 0 0.1rem
                    &.item_1
                        p
                            color #6D92E5
                            padding-top 0.1rem
                            font-weight 800
                        i
                            color #6D92E5
                        span
                            padding-left 0.1rem
                            font-weight 800
                    &.item_2
                        p
                            color #E76674
                            padding-top 0.1rem
                            font-weight 800
                        i
                            color #E76674
                        span
                            padding-left 0.1rem
                            font-weight 800
                    &.item_3
                        p
                            color #30B6B6
                            padding-top 0.1rem
                            font-weight 800
                        i
                            color #30B6B6
                        span
                            padding-left 0.1rem
                            font-weight 800
                    &.item_4
                        p
                            color #E98A4F
                            padding-top 0.1rem
                            font-weight 800
                        i
                            color #E98A4F
                        span
                            padding-left 0.1rem
                            font-weight 800
                    &.item_5
                        p
                            color #8972E5
                            padding-top 0.1rem
                            font-weight 800
                        i
                            color #8972E5
                        span
                            padding-left 0.1rem
                            font-weight 800
                    &.item_6
                        p
                            color #A1C17D
                            padding-top 0.1rem
                            font-weight 800
                        i
                            color #A1C17D
                        span
                            padding-left 0.1rem
                            font-weight 800
        .main-container
            position relative
            padding 0.2rem
            flex 1
            overflow hidden
            .box-wrapper
                height 100%
                .el-container
                    height 100%
                    .el-aside
                        width 8rem !important
                        height 100%
                        margin-right 0.2rem
                        background rgba(255,255,255,1)
                        box-shadow 0px 2px 6px 0px rgba(2, 130, 39, 0.35)
                        padding 0.1rem
                        .triangular
                            width 0
                            height 0
                            border 0.18rem solid #ffffff
                            position absolute
                            right 8rem
                            top: 1.5rem
                            border-color:transparent transparent transparent #ffffff;/*透明 透明 透明 灰*/
                            filter:drop-shadow(6px 0px 4px rgba(2, 130, 39, 0.35))
                        .aside-wrapper
                            height 100%
                            width 100%
                            p
                                font-weight 800;
                                font-size 0.16rem
                                padding-left 0.05rem
                                border-left 0.04rem solid #022782
                                margin-bottom 0.1rem
                                color #022782
                            .table-wrapper
                                height calc(100% - 0.3rem)
                                border 0.01rem solid rgba(240,240,240,1)
                                table
                                    font-size 0.14rem
                                    width 100%
                                    text-align center
                                    thead
                                        tr
                                            height 0.34rem
                                            background rgba(237,240,245,1)
                                            th
                                                color #022782
                                    tbody
                                        tr
                                            height 0.34rem
                                            &.active
                                                background rgba(241,245,252,1)
                                                color #1577D2
                                                font-wight 800
                                            &:nth-child(even)
                                                background rgba(247,249,254,1)
                                            input
                                                display none
                                                opacity 0
                                                &:checked+label
                                                    width 0.18rem
                                                    height 0.18rem
                                                    border 0.02rem solid #5588F1
                                                    border-radius 50%
                                                    color #5588F1
                                                    font-weight 800
                                            label
                                                width 0.18rem
                                                height 0.18rem
                                                border 0.02rem solid rgba(151,158,167,1)
                                                border-radius 50%
                                                display inline-block
                                                line-height 0.14rem
                                                text-align center
                                                color transparent
                                                cursor pointer


                    .el-header
                        width 7.9rem !important
                        height 3.1rem !important
                        margin-bottom  0.2rem
                        background rgba(255,255,255,1)
                        box-shadow 0px 2px 6px 0px rgba(2, 130, 39, 0.35)
                        padding 0.1rem
                        .header-wrapper
                            height 100%
                            position relative
                            &::after
                                content ""
                                width 0
                                height 0
                                border 0.18rem solid #ffffff
                                position absolute
                                left 3.8rem
                                bottom: -0.4rem
                                border-color #ffffff transparent transparent transparent/*透明 透明 透明 灰*/
                                z-index 99
                                filter drop-shadow(6px 6px 6px rgba(2, 130, 39, 0.35))
                            .title-wrapper
                                display flex
                                justify-content space-between
                                margin-bottom 0.1rem
                                p
                                    font-weight 800;
                                    font-size 0.16rem
                                    padding-left 0.05rem
                                    border-left 0.04rem solid #5588F1
                                    margin-bottom 0.1rem
                                    color #5588F1
                                .el-input
                                    width 2rem
                                    .el-input-group__append
                                        padding 0 0.1rem
                            .table-wrapper
                                height calc(100% - 0.4rem)
                                border 0.01rem solid rgba(240,240,240,1)
                                .el-table
                                    font-size 0.14rem
                                    .el-table__header-wrapper
                                        .el-table__header
                                            width 100% !important
                                            table-layout auto
                                            .cell
                                                padding 0
                                                text-align center
                                    .el-table__body-wrapper
                                        .el-table__body
                                            width 100% !important
                                            table-layout auto
                                            .el-table__row
                                                td
                                                    height 0.34rem
                                                    padding 0
                                                    .cell
                                                        padding 0
                                                        text-align center
                                                    .el-button
                                                        width 0.6rem
                                                        height 0.24rem
                                                        padding 0
                                                        span
                                                            font-size 0.16rem
                                                            color #5588F1
                                    .el-table__empty-block
                                        width auto !important
                                        min-height 0.34rem !important
                                        .el-table__empty-text
                                            height 0.34rem
                                            line-height 0.34rem
                    .el-main
                        width 7.9rem !important
                        height 3rem !important
                        background rgba(255,255,255,1)
                        box-shadow:0px 2px 6px 0px rgba(254,121,3,0.5);
                        padding 0.1rem
                        .main-wrapper
                            height 100%
                            p
                                font-weight 800;
                                font-size 0.16rem
                                padding-left 0.05rem
                                border-left 0.04rem solid #FE7903
                                margin-bottom 0.1rem
                                color #FE7903
                            .table-wrapper
                                height calc(100% - 0.3rem)
                                border 0.01rem solid rgba(240,240,240,1)
                                table
                                    font-size 0.14rem
                                    width 100%
                                    text-align center
                                    thead
                                        tr
                                            height 0.34rem
                                            background rgba(237,240,245,1)
                                            th
                                                color #FE7903
                                    tbody
                                        tr
                                            height 0.34rem
                                            &:nth-child(even)
                                                background rgba(254,250,247,1)
                                            i
                                                color #D5000F
                                                font-weight 800
                                                cursor pointer
    .custom-cell
        display flex
        justify-content center
        align-items center
        &.jiagong:before
            margin-left -0.35rem
            margin-right 0.12rem
            display inline-block
            width 24px
            height 16px
            content ''
            background url("../images/jiagong.png") center
            background-size 100%
</style>
